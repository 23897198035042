const labelConfig = {
  stratum: {
    appRouteUrl: {
      dashboard: "/home",
      records: "/records",
      records2: "/records2",
      recordCreate: "/record/add",
      recordEdit: "/record/edit/:recordId",
      containers: "/containers",
      containerCreate: "/container/add",
      containerEdit: "/container/edit",
      invoice: "/invoice",
      orders: "/orders",
      samples: "/samples",
      sampleCreate: "/samples/add",
      sampleEdit: "/samples/edit",
      samplesEdit: "/editSample",
      projects: "/projects",
      editProject: "/project/:tab/:projectId",
      editProjectPermission: "/project/permission/:tab/:projectId",
      editProjectPermissionDetailPage:
        "/project/permission/:tab/:projectId/:userId",
      contacts: "/contacts",
      addContact: "/contact/add",
      editContact: "/contact/edit/:tab/:id",
      editContactCompanyPermissions: "/contact/edit/:tab/:userId/:companyId",
      contactGroups: "/contactGroups",
      addContactGroup: "/contactGroups/add",
      editContactGroup: "/contactGroups/edit/:tab/:id",
      editContactGroupPermissions: "/contactGroups/edit/:tab/:id/:projectId",
      admin: "/admin",
      users: "/admin/users",
      searchUser: "/admin/user/add",
      addUser: "/admin/users/add/:tab",
      editUser: "/admin/users/edit/:tab/:id",
      editUserSitePermissions: "/admin/users/edit/:id/site",
      editUserCompanyPermissions: "/admin/users/edit/:id/company",
      editUserProjectPermissions: "/admin/users/edit/:id/project",
      countries: "/admin/countries",
      facilities: "/admin/facilities",
      editFacility: "/admin/facility/edit/:id",
      addFacility: "/admin/facility/add",
      licenses: "/admin/licenses",
      companies: "/admin/companies",
      addCompany: "/admin/company/add",
      editCompany: "/admin/company/edit/:tab/:companyId",
      fieldSector: "/admin/fieldSector",
      formations: "/admin/formations",
      unitOfMeasure: "/admin/unitOfMeasure",
      basin: "/admin/basin",
      containerTypeCategory: "/admin/containerTypeCategory",
      addContainerTypeCategory: "/admin/containerTypeCategory/add",
      editContainerTypeCategory:
        "/admin/containerTypeCategory/edit/:containerTypeCategoryId",
      containerType: "/admin/containerType",
      addContainerType: "/admin/containerType/add",
      editContainerType: "/admin/containerType/edit/:containerTypeId",
      sampleType: "/admin/sampleType",
      addSampleType: "/admin/sampleType/add",
      editSampleType: "/admin/sampleType/edit/:sampleTypeId",
      sampleTypeCategory: "/admin/sampleTypeCategory",
      addSampleTypeCategory: "/admin/sampleTypeCategory/add",
      editSampleTypeCategory:
        "/admin/sampleTypeCategory/edit/:sampleTypeCategoryId",
      storageType: "/admin/storageType",
      addStorageType: "/admin/storageType/add",
      editStorageType: "/admin/storageType/edit/:storageTypeId",
      fields: "/admin/fields",
      roles: "/admin/roles",
      addRole: "/admin/role/add",
      editRole: "/admin/role/edit/:roleId",
      catalogs: "/admin/catalogs",
      addCatalog: "/admin/catalog/add",
      editCatalog: "/admin/catalog/edit/:id",
      shippingCompany: "/admin/shippingCompany",
      sampleCondition: "/admin/sampleCondition",
      recordTemplate: "/admin/recordTemplate",
      addRecordTemplate: "/admin/recordTemplate/add",
      editRecordTemplate: "/admin/recordTemplate/edit/:recordTemplateId",
      printerSize: "/admin/printerSize",
      printers: "/admin/printers",
      addPrinter: "/admin/printers/add",
      editPrinter: "/admin/printers/edit/:printerId",
      printerTemplate: "/admin/printerTemplate",
      addPrinterTemplate: "/admin/printerTemplate/add",
      editPrinterTemplate: "/admin/printerTemplate/edit/:printerId",
      prepCode: "/admin/prepCode",
      designationCode: "/admin/designationCode",
      addDesignationCode: "/admin/designationCode/add",
      editDesignationCode: "/admin/designationCode/edit/:designationCodeId",
      displayRoom: "/admin/displayRoom",
      addDisplayRoom: "/admin/displayRoom/add",
      editDisplayRoom: "/admin/displayRoom/edit/:roomId",
      orderReceive: "/orderReceive",
      orderReceiveDetails: "/orderReceive/details/:orderId",
      orderCreate: "/order/create",
      orderEdit: "/order/edit/:orderId",
      sites: "/sites",
      siteAdd: "/site/add",
      siteEdit: "/site/edit/:siteId",
      siteView: "/site/view/:facilityId/:siteId",
      siteApproval: "/site/approval",
      siteApprovalEdit: "/site/approval/edit/:siteId",
      siteApprovalView: "/site/approval/view/:siteId",
      receipts: "/receipts",
      receiptApproval: "/receipt/approval",
      receiptAdd: "/receipt/add",
      receiptEdit: "/receipt/edit/:receiptId",
      orderType: "/orderType",
      subOrderType: "/subOrderType",
      editSiteUser: "/site/access/user/:userId/:siteId/:companyId",
      invoiceConfig: "/invoiceConfiguration",
      editInvoiceConfig: "/invoiceConfiguration/edit/:id",
      addInvoiceConfig: "/invoiceConfiguration/add",
      storageInvoice: "/invoice/storageInvoice",
      orderInvoice: "/invoice/orderInvoice",
      editStorageInvoice: "/storageInvoice/edit/:id",
      editOrderInvoice: "/orderInvoice/edit/:orderId",
      trainingVideos: "/trainingVideos",
      siteTrainingVideos: "/trainingVideos/site",
      locationTrainingVideos: "/trainingVideos/location",
      contactTrainingVideos: "/trainingVideos/contact",
      manualPage: "/trainingVideos/manual",
      adminTrainingVideos: "/trainingVideos/admin",
      siteTrainingVideoDetailPage: "/trainingVideos/site/view/:id",
      locationTrainingVideoDetailPage: "/trainingVideos/location/view/:id",
      contactTrainingVideoDetailPage: "/trainingVideos/contact/view/:id",
      adminTrainingVideoDetailPage: "/trainingVideos/admin/view/:id",
      resetInvoiceGenerationDate: "/resetInvoiceGenerationDate",
    },
    trainingVideos: "FAQ",
    addErrorTitle: "Add Error",
    companyRequiredMessage: "Company is required",
    priceRequiredMessage: "Price is required",
    addErrorMsg:
      "You have some changes in grid please save and try to add new.",
    unSavedChangesMessage:
      "There are some unsaved changes. Please save the changes to continue",
    samplesEdit: "editSample",
    user: "User",
    contact: "Contact",
    add: "Add",
    edit: "Edit",
    view: "View",
    delete: "Delete",
    deleted: "Deleted",
    failed: "Failed",
    approval: "Approval",
    approvedSuccessfully: "Approved Successfully",
    permissions: "Permissions",
    type: "Type",
    email: "Email",
    push: "Push",
    formDirtyMessage:
      "Data entered in the page has not been saved. Do you still want to leave?",
    yes: "Yes",
    no: "No",
    copied: "Copied!",
    permissionsCopied: "Permissions Copied!",
    save: "Save",
    discard: "Discard",
    invalid: "Invalid",
    chooseRowsDelete: "Please choose rows to delete",
    warning: "Warning",
    added: "Added",
    removed: "Removed",
    success: "Success",
    error: "Error",
    commentsAndQuantity: "Enter comments and quantity",
    enterComments: "Enter comments",
    enterQuantity: "Enter Quantity",
    generalInfoReturnErrorMessage:
      "Cannot assign location directly to General Info.  Please select a Container or Sample information.",
    userAlreadyExistsMessage:
      "The selected user already exists and is Inactive.  Please activate the user.",
    addMPLC: "Add MPLC",
    saveSamplesToAddMPLC: "Please save the samples to Add MPLC",
    chooseSamplesToAddMPLC: "Please choose rows to Add MPLC",
    saveSamplesToViewLocation: "Please save the samples to View Location",

    unAuthorizedMessage:
      "You do not have necessary permissions to perform this action.  Please contact the administrator.",
    permissionsRemoved: "Permission(s) Removed",
    permissionsAdded: "Permission Added",
    rolesRemoved: "Role(s) Removed",
    rolesAdded: "Role(s) Added",

    admin: "Admin",

    //Dashboard labels
    dashboard: "Dashboard",

    //Sample labels
    samples: "Samples",
    addSample: "Add Sample",
    editSample: "Edit Sample",
    editSamples: "Edit Samples",
    groupWarningMsg: "Choose at least two samples to create a group",
    alreadyCreatedGroupMsg: "Already created a group",
    existingRecordCreateWarning:
      "Once record is chosen and samples are associated with the record, this process cannot be reverted or cancelled.  Do you want to proceed?",

    //Record labels

    record: "Record",
    records: "Records",
    addRecord: "Add Record",
    editRecord: "Edit Record",

    //Container labels

    containers: "Containers",
    addContainer: "Add Container",
    editContainer: "Edit Container",
    chooseGeneralInfo: "Please choose rows to assign General Info",
    chooseContainer: "Please choose rows to assign Container",

    //Contact labels
    contacts: "Contacts",
    addContact: "Add Contact",
    editContact: "Edit Contact",
    contactGroups: "Contact Groups",
    addContactGroup: "Add Contact Group",
    editContactGroup: "Edit Contact Group",

    //User labels
    users: "Users",
    searchUser: "Search User",
    editUser: "Edit User",
    addUser: "Add User",
    noDataFound: "No data found for this search!!",
    userExists: "User Exists!",
    userExistsMessage: "Selected user already exist",

    //Country labels
    country: "Country",
    countryActivated: "Country Activated",

    //Facility labels
    facility: "Facility",
    editFacility: "Edit Facility",
    addFacility: "Add Facility",

    //License labels
    license: "License",

    //Company labels
    companies: "Companies",
    company: "Company",
    addCompany: "Add Company",
    editCompany: "Edit Company",
    locationName: "Location Name",
    mailingInfo: "Mailing Information",
    billingInfo: "Billing Information",
    mainContactInfo: "Accounting Main Contact Information",
    address1: "Address Line 1",
    address2: "Address Line 2",
    city: "City",
    state: "State",
    zip: "Zip",
    firstName: "First Name",
    lastName: "Last Name",
    title: "Title",
    mobile: "Mobile",
    phone: "Phone",
    ext: "Ext",
    fax: "Fax",

    //FieldSector labels
    fieldSector: "Field Sector",

    //Formation labels
    formation: "Formation",
    formationDeactivated: "Formation Deactivated",

    //Unit Of Measure labels
    unitOfMeasure: "Unit Of Measure",

    //Basin labels
    basin: "Basin",

    //Container Type labels
    containerType: "Container Type",
    addContainerType: "Add Container Type",
    editContainerType: "Edit Container Type",
    containerTypeDeactivated: "Container Type Successfully deactivated",

    //Container Type Category labels
    containerTypeCategory: "Container Type Category",
    addContainerTypeCategory: "Add Container Type Category",
    editContainerTypeCategory: "Edit Container Type Category",
    facilityAlias: "Facility Alias",
    containerTypeCategoryCreated: "Container Type Category Created",
    containerTypeCategoryCreatedMessage:
      "Container Type Category Created successfully.",
    containerTypeCategoryUpdated: "Container Type Category Updated",
    containerTypeCategoryUpdatedMessage:
      "Container Type Category Updated successfully.",
    containerTypeCategoryActivated: "Container Type Category Activated",
    containerTypeCategoryActivatedFailed:
      "Container Type Category Successfully Activate Failed",
    activateContainerTypeCategory: "Activate Container Type Category",
    activateContainerTypeCategoryMessage:
      "Are you sure you want to activate this Container Type Category?",
    containerTypeCategoryDeactivated:
      "Container Type Category Successfully Deactivated",
    containerTypeCategoryDeactivatedFailed:
      "Container Type Category Deactivate Failed",
    deactivateContainerTypeCategory: "Deactivate Container Type Category",
    deactivateContainerTypeCategoryMessage:
      "Are you sure you want to deactivate this Container Type Category?",
    containerTypeCategoryFacilityDeleted:
      "Container Type Category Alias Successfully Deleted",
    deleteContainerTypeCategoryFacility: "Delete Container Type Category Alias",
    deleteContainerTypeCategoryFacilityMessage:
      "Are you sure you want to Delete this Container Type Category Alias?",
    containerTypeCategoryAliasAdded: "Container Type Category Alias Added",

    invoiceConfigErrorMessage:
      "Invoice Configuration Name is unique. Cannot create duplicate configurations, please enter a different value.",

    //Sample Type labels
    sampleType: "Sample type",
    addSampleType: "Add Sample Type",
    editSampleType: "Edit Sample Type",

    //Sample Type Category labels
    sampleTypeCategory: "Sample Type Category",
    addSampleTypeCategory: "Add Sample Type Category",
    editSampleTypeCategory: "Edit Sample Type Category",
    activateSampleTypeCategory: "Activate Sample Type Category",
    activateSampleTypeCategoryMessage:
      "Are you sure you want to activate this Sample Type Category?",
    deactivateSampleTypeCategory: "Deactivate Sample Type Category",
    deactivateSampleTypeCategoryMessage:
      "Are you sure you want to deactivate this Sample Type Category?",
    sampleTypeCategoryDeactivatedMessage:
      "Sample Type Category Successfully deactivated",
    sampleTypeCategoryActivatedMessage:
      "Sample Type Category Successfully activated",
    sampleTypeCategoryActivateFailed: "Sample Type Category Activate Failed",
    successfullyDeleted: "Successfully Deleted",
    sampleTypeCategoryAliasDeleted:
      "Sample Type Category Alias Successfully deleted",
    deleteSampleTypeCategoryAlias: "Delete Sample Type Category Alias",
    deleteSampleTypeCategoryAliasMessage:
      "Are you sure you want to delete this Sample Type Category Alias?",
    alias: "Alias",
    addAlias: "Add Alias",
    sampleTypeCategoryInfo: "Sample Type Category Info",
    sampleTypeCategoryCreated: "Sample Type Category Created",
    sampleTypeCategoryCreatedMessage:
      "Sample Type Category Created successfully.",
    sampleTypeCategoryUpdated: "Sample Type Category Updated",
    sampleTypeCategoryUpdatedMessage:
      "Sample Type Category Updated successfully.",
    sampleTypeCategoryAliasAdded: "Sample Type Category Alias Added",

    //Storage type labels
    storageType: "Storage Type",
    addStorageType: "Add Storage Type",
    editStorageType: "Edit Storage Type",

    //Fields labels
    field: "Field",

    //Role labels
    role: "Role",
    addRole: "Add Role",
    editRole: "Edit Role",
    createdRole: "Created Role",
    roleCreatedSuccessfully: "Role Created Successfully",
    roleCreationFailed: "Role Created Failed",

    //Catalog labels
    masterCatalog: "Master Catalog",
    addCatalog: "Add Catalog",
    editCatalog: "Edit Catalog",

    //Shipping Company labels
    shippingCompany: "Shipping Company",

    //Sample Condition labels
    sampleCondition: "Sample Condition",

    //Record Template labels
    recordTemplate: "Record Template",
    addRecordTemplate: "Add Record Template",
    editRecordTemplate: "Edit Record Template",
    recordTemplateDeletedSuccessfullyMessage:
      "Record Template Successfully deleted",
    recordTemplateAssociatedMessage:
      "Template Cannot be deleted as it is associated with Sample Type(s)",

    //Printer labels
    printer: "Printer",
    printers: "Printers",
    printerSize: "Printer Size",
    editPrinter: "Edit Printer",
    addPrinter: "Add Printer",
    printerTemplate: "Printer Template",
    addPrinterTemplate: "Add Printer Template",
    editPrinterTemplate: "Edit Printer Template",

    //Prep Code labels
    prepCode: "Prep Code",

    //Designation Code labels
    designationCode: "Designation Code",
    addDesignationCode: "Add Designation Code",
    editDesignationCode: "Edit Designation Code",
    designationCodeAliasAdded: "Designation Code Alias Added",
    designationCodeAliasDeleted: "Designation Code Alias Successfully deleted",

    //Display Room labels
    displayRoom: "Display Room",
    addDisplayRoom: "Add Display Room",
    editDisplayRoom: "Edit Display Room",
    displayRoomActivated: "Display Room Activated",
    displayRoomActivatedMessage: "Display Room Activated Successfully",
    displayRoomDeactivated: "Display Room Successfully deactivated",

    //Project labels
    projects: "Projects",
    editProject: "Edit Project",
    projectRemoved: "Project successfully removed",

    // Receipt labels

    receipt: "Receipt",
    receiptNo: "Receipt No",
    receiptNumbers: "Receipt Number(s)",
    receiptId: "Receipt Id",
    receipts: "Receipts",
    receiptApproval: "Receipt Approval",
    receiptsForApproval: "Receipts For Approval",
    newReceipt: "New Receipt",
    addReceipt: "Add Receipt",
    editReceipt: "Edit Receipt",
    deleteReceipt: "Delete Receipt",
    splitReceipt: "Split Receipt",
    split: "Split",
    cancelReceipt: "Cancel Receipt",
    parentReceipt: "Parent Receipt",
    childReceipt: "Child Receipt",
    searchReceipt: "Search Receipt",
    storageReceipt: "Storage Receipt",
    storageReceipts: "Storage Receipts",
    receiptCreated: "Storage Receipt created",
    receiptUpdated: "Storage Receipt updated",
    receiptCreatedSuccessfully: "Storage Receipt created successfully.",
    receiptUpdatedSuccessfully: "Storage Receipt updated successfully.",
    receiptDeletedSuccessfully: "Storage Receipt deleted successfully.",
    receiptCancelled: "Storage Receipt Cancelled",
    receiptCancelledSuccessfully: "Storage Receipt cancelled successfully.",
    movedToQC: "Moved to QC",
    movedToInprogress: "Moved to Inprogress",
    receiptApprovedSuccess: "Receipt approved successfully",
    receiptSplitSuccess: "Receipt split successfully",
    receiptMovedToQCSuccess: "Storage Receipt moved to QC successfully.",
    receiptMovedToInprogressSuccess:
      "Storage Receipt moved to Inprogress successfully.",
    receiptDeleteConfirmationMsg:
      "Are you sure you want to delete this Receipt?",
    receiptDeleteConfirmationMsgWithWarning:
      "Are you sure you want to delete this Receipt? All record related to this receipt will be deleted as well. This action cannot be undone.",
    approveReceipt: "Approve Receipt",
    receiptNoteCreateSuccess: "Receipt Note Added",
    receiptNoteCreateFailed: "Receipt Note Add Failed",
    receiptNoteUpdateSuccess: "Receipt Note Updated",
    receiptNoteUpdateFailed: "Receipt Note Update Failed",
    receiptNoteDeleteSuccess: "Receipt Note Deleted",
    receiptNoteDeleteFailed: "Receipt Note Delete Failed",
    receiptAttachmentDeleted: "Receipt Attachment Deleted Successfully",
    receiptAttachmentDeleteFailed: "Receipt Attachment Delete Failed",
    subOrderType: "Sub Order Type",
    subOrderTypeName: "Sub Order Type Name",
    addsubOrderType: "Add Sub Order Type",
    subOrderTypeRequired: "Sub order type name is required.",
    generalInfoReceiptDeleteErrorMessage:
      "General Info is associated with one or more order(s). It cannot be deleted.",
    latitudeErrorMessage:
      "The latitude must fall within the range of -90 to 90 degrees.",
    longitudeErrorMessage:
      "The longitude must fall within the range of -180 to 180 degrees.",
    latlngWarning: "Enter both latitude and longitude",

    // Location Labels
    location: "Location",
    locationSearch: "Search",
    locationMove: "Move",
    locationMoveReset: "Reset",
    locationMoveParent: "Destination Barcode",
    locationMoveChild: "Child Barcode",
    locationMoveCreate: "Search",
    locationMoveParentBarcode: "Destination Barcode",
    locationMoveChildBarcode: "Child Barcode",
    locationSite: "Site",

    // Order Labels
    orderAcknowledgeWithoutProject:
      "Cannot acknowledge this order without project information.  Please select a project to proceed.",
    orderMenu: [
      {
        key: "order-display",
        displayName: "Calendar",
        path: "order/calendar",
      },
    ],
    order: "Order",
    orderReceive: "Order Receive",
    orderReceiveDetails: "Order Receive Details",
    orders: "Orders",
    orderTypeName: "Order Type Name",
    orderTypeRequired: "Order Type Name Required",
    addOrderType: "Add Order Type",
    orderType: "Order Type",
    orderPurpose: "Purpose",
    orderTypeCatDisplayRoom: "DisplayRoom",
    orderTypeCatExternalShipping: "Shipping",
    orderTypeCatInternalShipping: "Shipping",
    orderTypeCatCheck: "Check",
    orderTypeAddedSuccessfully: "Order Type added successfully",
    orderTypeAddedFailed: "Order Type add failed",
    orderTypeUpdatedSuccesfully: "Order Type Successfully Updated",
    orderTypeUpdateFailed: "Order Type Update Failed",
    orderTypeDeActivateConfirmation:
      "Are you sure you want to deactivate this Order Type?",
    orderTypeActivateConfirmation:
      "Are you sure you want to activate this Order Type?",
    deactivateOrderType: "Deactivate Order Type",
    orderTypeSuccessfullyDeactivated: "Order Type Successfully deactivated",
    successfullyDeactivated: "Successfully Deactivated",
    activateOrderType: "Activate Order Type",
    successfullyActivated: "Successfully Activated",
    orderTypeDeactivated: "Order Type Activated",
    activateFailed: "Activate Failed",
    orderTypeActivateFailed: "Order Type Activate Failed",
    orderTypes: "Order Types",
    createOrder: "Create Order",
    createSubOrder: "Create Sub Order",
    editOrder: "Edit Order",
    orderCreated: "Order Created",
    orderCreatedSuccessfully: "Order Created Successfully",
    shippingOrder: "Domestic Shipments",
    orderStatus: "Order Status",
    orderUpdatedSuccessfully: "Order Updated Successfully",
    orderUpdated: "Order Updated",
    orderId: "Order Id",
    orderRequestNo: "Order No",
    orderDetails: "Order Details",
    orderTypeIsRequired: "Order Type is required",
    addToOrder: "Add to Order",
    addOrder: "Add Order",
    displayRoomUpdated: "Display Room Updated",
    displayRoomUpdatedSuccessfully: "Display Room Updated successfully",
    displayRoom: "Display Room",
    orderApproved: "Order Approved",
    orderApprovedMsg: "Order Approved Successfully",
    orderCancelled: "Order Cancelled",
    orderCancelledMsg: "Order Cancelled Successfully",
    orderAcknowledged: "Order Acknowledged",
    orderAcknowledgedMsg: "Order Acknowledged Successfully",
    orderMovedToCoreDisplay: "Order moved to Core Display",
    orderMovedToCoreDisplayMsg: "Order moved to Core Display Successfully",
    orderMovedToMarkCompleted: "Order Completed",
    orderMovedToMarkCompletedMsg: "Order completed successfully",
    orderReservedMsg:
      "These chosen records/items are already associated with a different order. Do you still wish to proceed with adding them?",
    orderMovedToReadyForChecking: "Ready for Checking",
    orderMovedToReadyForCheckingMsg: "Order moved to Ready for Checking",
    orderMovedToReadyForTransport: "Ready for Transport",
    orderMovedToReadyForTransportMsg:
      "Order moved to Ready for Transport Successfully",
    orderMovedToTransportOrdered: "Transport Ordered",
    orderMovedToTransportOrderedMsg:
      "Order moved to Transport Ordered Successfully",
    orderMovedToshipped: "Order shipped",
    orderMovedToshippedMsg: "Order shipped Successfully",

    initiateRequest: "Initiate Order",
    addOrderDetails: "Add Details",
    chooseRecords: "Choose Records",
    orderAttachmentDeleted: "Order Attachment Deleted Successfully",
    orderAttachmentDeleteFailed: "Order Attachment Delete Failed",
    orderNoteDeletedSuccessfully: "Order Note Deleted Successfully",
    orderNoteDeleteFailed: "Order Note Delete Failed",

    // Site labels

    site: "Well",
    siteAlternate: "Alternate Well Name",
    sites: "Wells",
    siteId: "Well Id",
    deletedSuccessfully: "Deleted Successfully",
    deleteFailed: "Delete Failed",
    note: "Note",
    siteApproval: "Well Approval",
    siteForApproval: "Well for Approval",
    selectSite: "Select Well",
    addSite: "Add Well",
    addSites: "Add Well",
    editSite: "Edit Well",
    viewSite: "View Well",
    sitePermissions: "Well Permissions",
    siteName: "Well Name",
    siteIsRequired: "Well Required",
    siteAttachmentDeleted: "Well Attachment Deleted Successfully",
    siteAttachmentDeleteFailed: "Well Attachment Delete Failed",
    siteAdded: "Well Added",
    siteAddedFailed: "Well Added failed",
    siteAddedSuccessfully: "Well Added Successfully",
    siteRemovedSuccessfully: "Well Removed Successfully",
    clientSiteId: "Client Well Id",
    removeSite: "Remove Well",
    editSiteNewTab: "Edit Site",
    removeProject: "Remove Project",
    siteInformation: "Well Information",
    pleaseChooseSite: "Please Choose Well",
    siteVerifiedSuccessfully: "Well Verified Successfully",
    deleteSiteAliasConfirmation:
      "Are you sure you want to delete this Well Alias?",
    deleteSiteAlias: "Delete Well Alias",
    siteAliasDeletedSuccessfully: "Well Alias Successfully deleted",
    siteNoteAddFailed: "Well Note Add Failed",
    siteNoteAdded: "Well Note Added",
    siteNoteUpdateFailed: "Well Note Update Failed",
    siteNoteDeleteFailed: "Well Note Delete Failed",
    siteNoteDeleted: "Well Note Deleted",
    siteNoteUpdated: "Well Note Updated",
    failedToCreateSite:
      "Well Name is unique.  The well you are trying to create already exists.",
    siteAlreadyExist: "Well already exist with same name",
    chooseSite: "Choose Well",
    associateSite: "Associate Well",
    editSiteUser: "Edit Well User",

    //Empty box
    emptyBoxInventoryLogs: "Empty Box Inventory Logs",
    emptyBoxInventory: "Empty Box Inventory",

    //Invoice
    editInvoice: "Edit Invoice",
    invoice: "Invoice",
    storage: "Storage",
    invoiceServiceItemUpdate: "Invoice Service Item Updated",
    order: "Order",
    orderInvoice: "Order Invoice",
    storageInvoice: "Storage Invoice",

    invoiceAvailable:
      "Have you completed adding all the required service items to this invoice?",
    invoiceUnAvailable:
      "No service items have been included in this invoice. Would you like to add all the required Service items to finalize this order?",
    exeptOrder:
      "This action cannot be undone.Are you sure you want to exempt this Order from invoicing?",

    storageInvoice: "Storage Invoice",
    editStorageInvoice: "Edit Storage Invoice",

    //Invoice Configuration
    invoiceConfig: "Invoice Configuration",
    editInvoiceConfig: "Edit Invoice Configuration",
    addInvoiceConfig: "Add Invoice Configuration",

    //Reset Invoice Generation Date
    resetInvoiceGenerationDate: "Reset Invoice Generation Date",
  },
  // Adnoc
  adnoc: {
    appRouteUrl: {
      orders: "/inHouseRequests",
      orderReceive: "/orderReceive",
      orderReceiveDetails: "/orderReceive/details/:orderId",
      orderCreate: "/inHouseRequest/create",
      orderEdit: "/inHouseRequest/edit/:orderId",
      sites: "/wellbores",
      siteAdd: "/wellbore/addWellbore",
      siteEdit: "/wellbore/editWellbore/:siteId",
      siteView: "/wellbore/viewWellbore/:facilityId/:siteId",
      siteApproval: "/wellboreApproval",
      siteApprovalEdit: "/wellboreApproval/editWellbore/:siteId",
      siteApprovalView: "/wellboreApproval/viewWellbore:siteId",
      receipts: "/registrations",
      receiptApproval: "/registration/approval",
      receiptAdd: "/registration/add",
      receiptEdit: "/edit/registration/:receiptId",
      orderType: "/inHouseType",
      subOrderType: "/serviceType",
      editSiteUser: "/wellbore/access/user/:userId/:siteId/:companyId",
    },
    add: "Add",
    edit: "Edit",
    delete: "Delete",
    deleted: "Deleted",
    failed: "Failed",
    approval: "Approval",
    approvedSuccessfully: "Approved Successfully",
    permissions: "Permissions",
    type: "Type",
    email: "Email",
    push: "Push",
    // Receipt labels

    receipt: "Registration",
    receiptNo: "Registration No",
    receiptNumbers: "Registration Number(s)",
    receiptId: "Registration Id",
    receipts: "Registrations",
    receiptApproval: "Registration Approval",
    newReceipt: "New Registration",
    addReceipt: "Add Registration",
    editReceipt: "Edit Registration",
    deleteReceipt: "Delete Registration",
    splitReceipt: "Split Registration",
    split: "Split",
    cancelReceipt: "Cancel Registration",
    parentReceipt: "Parent Registration",
    childReceipt: "Child Registration",
    searchReceipt: "Search Registration",
    storageReceipt: "Registration",
    storageReceipts: "Registrations",
    receiptCreated: "Registration created",
    receiptUpdated: "Registration updated",
    receiptCreatedSuccessfully: "Registration created successfully.",
    receiptUpdatedSuccessfully: "Registration updated successfully.",
    receiptDeletedSuccessfully: "Registration deleted successfully.",
    receiptCancelled: "Registration Cancelled",
    receiptCancelledSuccessfully: "Registration cancelled successfully.",
    movedToQC: "Moved to QC",
    movedToInprogress: "Moved to Inprogress",
    receiptApprovedSuccess: "Registration approved successfully",
    receiptSplitSuccess: "Registration split successfully",
    receiptMovedToQCSuccess: "Registration moved to QC successfully.",
    receiptMovedToInprogressSuccess:
      "Registration moved to Inprogress successfully.",
    receiptDeleteConfirmationMsg:
      "Are you sure you want to delete this Registration?",
    receiptDeleteConfirmationMsgWithWarning:
      "Are you sure you want to delete this Registration? All record related to this registration will be deleted as well. This action cannot be undone.",
    approveReceipt: "Approve Registration",
    receiptNoteCreateSuccess: "Registration Note Added",
    receiptNoteCreateFailed: "Registration Note Add Failed",
    receiptNoteUpdateSuccess: "Registration Note Updated",
    receiptNoteUpdateFailed: "Registration Note Update Failed",
    receiptNoteDeleteSuccess: "Registration Note Deleted",
    receiptNoteDeleteFailed: "Registration Note Delete Failed",
    receiptAttachmentDeleted: "Registration Attachment Deleted Successfully",
    receiptAttachmentDeleteFailed: "Registration Attachment Delete Failed",

    // Location Labels
    location: "Location",
    locationSearch: "Search",
    locationMove: "Move",
    locationMoveReset: "Reset",
    locationMoveParent: "Destination Barcode",
    locationMoveChild: "Child Barcode",
    locationMoveCreate: "Search",
    locationMoveParentBarcode: "Destination Barcode",
    locationMoveChildBarcode: "Child Barcode",
    locationSite: "Wellbore",

    // Order Labels

    order: "In-House",
    orderReceive: "In-House Receive",
    rderReceiveDetails: "In-House Receive Details",
    orders: "In-House Requests",
    orderTypeName: "In-House Type Name",
    orderTypeRequired: "In-House Type Name Required",
    addOrderType: "Add In-House Type",
    orderType: "In-House Type",
    orderPurpose: "Purpose",
    orderTypeAddedSuccessfully: "In-House Type added successfully",
    orderTypeAddedFailed: "In-House Type add failed",
    orderTypeUpdatedSuccesfully: "In-House Type Successfully Updated",
    orderTypeUpdateFailed: "In-House Type Update Failed",
    orderTypeDeActivateConfirmation:
      "Are you sure you want to deactivate this In-House Type?",
    orderTypeActivateConfirmation:
      "Are you sure you want to activate this In-House Type?",
    deactivateOrderType: "Deactivate In-House Type",
    orderTypeSuccessfullyDeactivated: "In-House Successfully deactivated",
    successfullyDeactivated: "Successfully Deactivated",
    successfullyActivated: "Successfully Activated",
    activateOrderType: "Activate In-House Type",
    orderTypeDeactivated: "In-House Type Activated",
    activateFailed: "Activate Failed",
    orderTypeActivateFailed: "In-House Type Activate Failed",
    orderTypes: "In-House Types",
    createOrder: "Create In-House",
    createSubOrder: "Create Sub In-House",

    editOrder: "Edit In-House",
    orderCreated: "In-House Created",
    orderCreatedSuccessfully: "In-House Created Successfully",
    shippingOrder: "Shipment",
    orderStatus: "In-House Status",
    orderUpdatedSuccessfully: "In-House Updated Successfully",
    orderUpdated: "In-House Updated",
    orderId: "In-House Id",
    orderRequestNo: "Request No",
    orderDetails: "In-House Details",
    orderTypeIsRequired: "In-House Type is required",
    addToOrder: "Add to In-House",
    addOrder: "Add In-House",
    displayRoomUpdated: "Core Study Service Booking Updated",
    displayRoomUpdatedSuccessfully:
      "Core Study Service Booking Updated successfully",
    displayRoom: "Core Study Service Booking",
    orderApproved: "In-House Request Approved",
    orderApprovedMsg: "In-House Request Approved Successfully",
    orderCancelled: "In-House Request Cancelled",
    orderCancelledMsg: "In-House Request Cancelled Successfully",
    orderAcknowledged: "In-House Request Acknowledged",
    orderAcknowledgedMsg: "In-House Request Acknowledged Successfully",
    orderMovedToCoreDisplay: "In-House Request moved to Core Display",
    orderMovedToCoreDisplayMsg:
      "In-House Request moved to Core Display Successfully",
    orderMovedToMarkCompleted: "In-House Request Completed",
    orderMovedToMarkCompletedMsg: "In-House Request Completed Successfully",
    orderMovedToshipped: "In-House Request shipped",
    orderMovedToshippedMsg: "In-House Request shipped Successfully",
    initiateRequest: "Initiate Request",
    addOrderDetails: "Add Details",
    chooseRecords: "Choose Records",
    orderAttachmentDeleted: "In-House Attachment Deleted Successfully",
    orderAttachmentDeleteFailed: "In-House Attachment Delete Failed",
    orderNoteDeletedSuccessfully: "In-House Note Deleted Successfully",
    orderNoteDeleteFailed: "In-House Note Delete Failed",

    // Site labels

    site: "Wellbore",
    sites: "Wellbores",
    siteId: "Wellbore Id",
    deletedSuccessfully: "Deleted Successfully",
    deleteFailed: "Delete Failed",
    note: "Note",
    siteApproval: "Wellbore Approval",
    siteForApproval: "Wellbore for Approval",
    selectSite: "Select Wellbore",
    addSite: "Add Wellbore",
    addSites: "Add Wellbores",
    editSite: "Edit Wellbore",
    viewSite: "View Wellbore",
    sitePermissions: "Wellbore Permissions",
    siteName: "Wellbore Name",
    siteIsRequired: "Wellbore is required",
    siteAttachmentDeleted: "Wellbore Attachment Deleted Successfully",
    siteAttachmentDeleteFailed: "Wellbore Attachment Delete Failed",
    siteAdded: "Wellbore Added",
    siteAddedFailed: "Wellbore Added failed",
    siteAddedSuccessfully: "Wellbore Added Successfully",
    siteRemovedSuccessfully: "Wellbore Removed Successfully",
    clientSiteId: "Wellbore Id",
    removeSite: "Remove Wellbore",
    siteInformation: "Wellbore Information",
    pleaseChooseSite: "Please Choose Wellbore",
    siteVerifiedSuccessfully: "Wellbore Verified Successfully",
    deleteSiteAliasConfirmation:
      "Are you sure you want to delete this Wellbore Alias?",
    deleteSiteAlias: "Delete Wellbore Alias",
    siteAliasDeletedSuccessfully: "Wellbore Alias Successfully deleted",
    siteNoteAddFailed: "Wellbore Note Add Failed",
    siteNoteAdded: "Wellbore Note Added",
    siteNoteUpdateFailed: "Wellbore Note Update Failed",
    siteNoteDeleteFailed: "Wellbore Note Delete Failed",
    siteNoteDeleted: "Wellbore Note Deleted",
    siteNoteUpdated: "Wellbore Note Updated",
    failedToCreateSite: "Failed to create site",
    siteAlreadyExist: "Wellbore already exist with same name",
    chooseSite: "Choose Wellbore",
    associateSite: "Associate Wellbore",
    editSiteUser: "Edit Wellbore User",

    //Empty box
    emptyBoxInventoryLogs: "Empty Box Inventory Logs",
    emptyBoxInventory: "Empty Box Inventory",

    subOrderType: "Service Type",
    subOrderTypeName: "Service Type Name",
    addsubOrderType: "Add Service Type",
    subOrderTypeName: "Service Item Name",
    subOrderTypeRequired: "Service Item Name is required.",
  },
};

export default labelConfig;
