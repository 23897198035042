import ApiService from "services/ApiService";
import { LoadStatus } from "./constant";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// Async thunk to fetch data for a record ID
export const fetchFacilityPermission = createAsyncThunk(
    "authz/facility-permission",
    async (userId, { getState, dispatch }) => {
        const resp = await ApiService.fetchData({
            url: `api/v1/access/authz/userGeneralPermission?userId=${userId}`,
            method: "get",
            data: {}
        })
        dispatch(setUserPermission(resp.data))
    }
)

export const fetchResourcePermission = createAsyncThunk(
    "authz/resource-permission",
    async (userId, { getState, dispatch }) => {
        const resp = await ApiService.fetchData({
            url: `api/v1/access/authz/userResourcePermission?userId=${userId}`,
            method: "get",
            data: {}
        })
        dispatch(setResourcePermission(resp.data))
    }
)

export const fetchFacilities = createAsyncThunk(
    "authz/facilities",
    async (arg, { getState, dispatch }) => {
        const resp = await ApiService.fetchData({
            url: `api/v1/admin/facility?active=false`,
            method: "get",
            data: {}
        })
        dispatch(setFacilities(resp.data))
    }
)

const slice = createSlice({
    name: "authz",
    initialState: {
        load: {
            facility: LoadStatus.TO_LOAD,
            userPermission: LoadStatus.TO_LOAD,
            resourcePermission: LoadStatus.TO_LOAD,
        },
        permissions: {
            general: [],
            facility: [],
            resource: []
        },
        lookup: {
            facilities: []
        }
    },
    reducers: {
        setFacilities: (state, action) => {
            state.lookup.facilities = action.payload;
        },
        setUserPermission: (state, action) => {
            state.permissions.general = action.payload.filter(p => p?.facilityId === null)
            state.permissions.facility = action.payload.filter(p => p?.facilityId !== null)
        },
        setResourcePermission: (state, action) => {
            state.permissions.resource = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFacilities.pending, (state, action) => {
            state.load.facility = LoadStatus.LOADING
        }).addCase(fetchFacilities.fulfilled, (state, action) => {
            state.load.facility = LoadStatus.SUCCESS
        }).addCase(fetchFacilities.rejected, (state, action) => {
            state.load.facility = LoadStatus.REJECTED
        });

        builder.addCase(fetchFacilityPermission.pending, (state, action) => {
            state.load.userPermission = LoadStatus.LOADING
        }).addCase(fetchFacilityPermission.fulfilled, (state, action) => {
            state.load.userPermission = LoadStatus.SUCCESS
        }).addCase(fetchFacilityPermission.rejected, (state, action) => {
            state.load.userPermission = LoadStatus.REJECTED
        });

        builder.addCase(fetchResourcePermission.pending, (state, action) => {
            state.load.resourcePermission = LoadStatus.LOADING
        }).addCase(fetchResourcePermission.fulfilled, (state, action) => {
            state.load.resourcePermission = LoadStatus.SUCCESS
        }).addCase(fetchResourcePermission.rejected, (state, action) => {
            state.load.resourcePermission = LoadStatus.REJECTED
        });
    }
})


export const {
    setFacilities,
    setUserPermission,
    setResourcePermission
} = slice.actions;

export const authzReducer = slice.reducer;
