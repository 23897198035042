import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import master from './master'
import { authzReducer } from './authz/authz-slice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        master,
        authz: authzReducer,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
