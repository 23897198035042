import { method } from "lodash"
import ApiService from "./ApiService"


// get list of values
export async function apiGetStorageReceiptCategory (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/Receipt/master/categories`,
        method: 'get',
    })
}

//crud

export async function apiDeleteStorageReceipt (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/Receipt/${data.id}`,
        method: 'delete',
        data
    })
}

export async function apiPutStorageReceipt (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/Receipt`,
        method: 'put',
        data
    })

}

export async function apiPostStorageReceipt (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/Receipt`,
        method: 'post',
        data
    })
}

export async function apiGetStorageReceiptById (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/Receipt/${data.id}`,
        method: 'get',
    })
}

export async function apiGetStorageReceiptList (data) {
    return ApiService.fetchData({
        url: "/api/v1/Receipts/Receipt",
        method: 'get',
    })
}

export async function apiGetLocations (data) {
    return ApiService.fetchData({
        url: '/api/v1/admin/Location',
        method: 'get',
        data
    })
}

//Split receipt

export async function apiPutSplitReceipt (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/Receipt/${data.id}/${data.NoOfReceiptstoSplit}`,
        method: 'put',
        data
    })
}
export async function apiGetChildReceiptsById (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/Receipt/GetSplitReceipts/${data.id}`,
        method: 'get',
        data
    })
}

//cancel receipt
export async function apiCancelReceipt (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/Receipt/status/cancel/${data.id}`,
        method: 'put',
        data: {}
    })
}

//Validation for QC
export async function apiValidationForQC (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/Receipt/receipt/validationforqc/${data.id}`,
        method: 'get',
        data: {}
    })
}

//Sent receipt to QC
export async function apiSendReceiptToQC (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/Receipt/status/qc/${data.id}`,
        method: 'put',
        data: {}
    })
}

export async function apiSendReceiptReturnToQC (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/Receipt/status/return/qc/${data.id}`,
        method: 'put',
        data: {}
    })
}

//change receipt to inprogress

export async function apiSendReceiptToInProgress(data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/Receipt/status/in-progress/${data.id}`,
        method: 'put',
        data: {}
    })
}

// Complete Receipt

export async function apiCompleteReceipt(data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/Receipt/status/complete/${data.id}`,
        method: 'put',
        data: null
    })
}

export async function apiCompleteReceiptReturn(data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/Receipt/status/return/complete/facility/${data.id}`,
        method: 'put',
        data: null
    })
}

//validate receipt

export async function apiGetValidateReceiptWorkbench (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/Receipt/validate/${data.id}`,
        method: 'get',
        data
    })
}

//Assign location to receipt

export async function apiGetAssignLocationToReceipt (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptLocation/containers?receiptId=${data.id}`,
        method: 'get',
        data
    })
}

export async function apiGetAssignLocationToWorkBenchReceipt (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptWorkBench/containers?receiptId=${data?.receiptId}&receiptWorkBenchId=${data?.receiptWorkBenchId}`,
        method: 'get'
    })
}

export async function apiAssignLocationToReceipt (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptLocation`,
        method: 'post',
        data
    })
}


export async function apiRemoveWorkbenchLocation (data, barcode) {
    return ApiService.fetchData({
        url: `api/v1/Receipts/ReceiptWorkBench/remove/location/${barcode}`,
        method: 'post',
        data
    })
}

export async function apiDeleteAssignLocationToReceipt (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptLocation/${data.id}`,
        method: 'delete',
        data
    })
}

export async function apiPutAssignLocationToReceipt (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptLocation`,
        method: 'put',
        data
    })
}


// Get Sample Type 

export async function apiGetReceiptSampleTypeList (data) {
    return ApiService.fetchData({
        url: '/api/v1/admin/ReceiptSampleType',
        method: 'get',
        data
    })
}

export async function apiPostReceiptSampleType (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptSampleType`,
        method: 'post',
        data
    })
}


export async function apiPutReceiptOrderRemoveItem(data, receiptId){
    return ApiService.fetchData({
        url: `/api/v1/Orders/OrderRecord/return/order/remove/item?receiptId=${receiptId}`,
        method: 'put',
        data
    })
}

// sites

export async function apiPostReceiptSite (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptSite`,
        method: 'post',
        data
    })
}

export async function apiGetReceiptSitesById (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptSite/sites?receiptId=${data.id}`,
        method: 'get',
        data
    })
}

export async function apiDeleteReceiptSite (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptSite`,
        method: 'delete',
        data
    })
}

// sites

export async function apiPostReceiptProject (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptProject/associate/project?receiptId=${data.id}`,
        method: 'post',
        data: data.projectIds
    })
}

export async function apiGetReceiptProjectsById (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptProject/project?receiptId=${data.id}`,
        method: 'get',
        data
    })
}

export async function apiDeleteReceiptProject (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptProject/${data.id}`,
        method: 'delete',
        data
    })
}

export async function apiGetSampeTypeTempaltes (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptSampleType/sample_types?receiptId=${data.id}`,
        method: 'get',
        data
    })
}

export async function apiGetWorkbenchTemplates (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptWorkBench/workbenches?receiptId=${data.id}`,
        method: 'get',
        data
    })
}



export async function apiPostWorkBenchAssignLocation (data, barcode){
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptWorkBench/assign/location/${barcode}`,
        method: 'post',
        data
    })
}

export async function apiPostReceiptWorkbench (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptWorkBench`,
        method: 'post',
        data
    })
}

export async function apiPutReceiptWorkbench (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptWorkBench`,
        method: 'put',
        data
    })
}

export async function apiDeletetReceiptWorkbench (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptWorkBench/${data.id}`,
        method: 'DELETE',
        data
    })
}

//Attachment

export async function apiDeleteReceiptAttachment(data){
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptAttachment/${data.id}`,
        method: 'DELETE',
    })
}

export async function  apiGetReceiptAttachmentsById(data){
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptAttachment/attachments?receiptId=${data.id}`,
        method: 'GET',
        data
    })
}

//workbench

export async function  apiGetWorkBenchGeneralInfo(data){
    return ApiService.fetchData({
        url: `/api/v1/Workbench/WorkBenchGeneralInfo/${data.id}`,
        method: 'GET',
    })
}

export async function  apiUpdateWorkBenchGeneralInfo(data){
    return ApiService.fetchData({
        url: `/api/v1/Workbench/WorkBenchGeneralInfo`,
        method: 'POST',
        data
    })
}

export async function  apiGetWorkBenchSample(data){
    return ApiService.fetchData({
        url: `/api/v1/Workbench/WorkBenchSample/${data.id}`,
        method: 'GET',
    })
}

export async function  apiUpdateWorkBenchSample(data){
    return ApiService.fetchData({
        url: `/api/v1/Workbench/WorkBenchSample`,
        method: 'POST',
        data
    })
}

export async function  apiGetWorkBenchContainer(data){
    return ApiService.fetchData({
        url: `/api/v1/Workbench/WorkBenchContainer/${data.id}`,
        method: 'GET',
    })
}


export async function apiGetGeneralInfoCount(data) {
    return ApiService.fetchData({
        url: `api/v1/Workbench/WorkBenchContainer/count/${data.id}`,
        method: 'GET',
    })
}

export async function apiGetContainerCount(data) {
    return ApiService.fetchData({
        url: `api/v1/Workbench/WorkBenchGeneralInfo/count/${data.id}`,
        method: 'GET',
    })
}

export async function apiGetSampleCount(data) {
    return ApiService.fetchData({
        url: `api/v1/Workbench/WorkBenchSample/count/${data.id}`,
        method: 'GET',
    })
}


export async function  apiUpdateWorkBenchContainer(data){
    return ApiService.fetchData({
        url: `/api/v1/Workbench/WorkBenchContainer`,
        method: 'POST',
        data
    })
}

export async function  apiGetDesignationCodes(data){
    return ApiService.fetchData({
        url: `/api/v1/admin/DesignationCode`,
        method: 'GET',
    })
}

export async function  apiGetDesignationCodesByFacilityId(data){
    return ApiService.fetchData({
        url: `/api/v1/admin/DesignationCode/get`,
        method: 'GET',
    })
}

export async function apiSetContainerNumber(receiptId){
    return ApiService.fetchData({
        url: `/api/v1/Workbench/WorkBenchContainer/setcontainernumber?receiptId=${receiptId}`,
        method: 'PUT',
    })
}

export async function apiSetContainerNumberPerCore(receiptId){
    return ApiService.fetchData({
        url: `/api/v1/Workbench/WorkBenchContainer/setcontainernumberpercore?receiptId=${receiptId}`,
        method: 'PUT',
    })
}

export async function  apiGetPrepCodes(data){
    return ApiService.fetchData({
        url: `/api/v1/admin/PrepCode`,
        method: 'GET',
    })
}

export async function  apiDeleteWorkBenchGeneralInfo(data){
    return ApiService.fetchData({
        url: `/api/v1/Workbench/WorkBenchGeneralInfo`,
        method: 'DELETE',
        data
    })
}

export async function  apiDeleteWorkBenchContainer(data){
    return ApiService.fetchData({
        url: `/api/v1/Workbench/WorkBenchContainer`,
        method: 'DELETE',
        data
    })
}

export async function  apiDeleteWorkBenchSample(data){
    return ApiService.fetchData({
        url: `/api/v1/Workbench/WorkBenchSample`,
        method: 'DELETE',
        data
    })
}

//workbench associate
export async function  apiAssociateGeneralInfoToContainer(data,generalInfoId){
    return ApiService.fetchData({
        url: `/api/v1/Workbench/WorkBenchContainer/associate/GeneralInfo/${generalInfoId}`,
        method: 'POST',
        data
    })
}

export async function  apiAssociateGeneralInfoToSample(data,generalInfoId){
    return ApiService.fetchData({
        url: `/api/v1/Workbench/WorkBenchSample/associate/GeneralInfo/${generalInfoId}`,
        method: 'POST',
        data
    })
}

export async function  apiAssociateContainerToSample(data,generalInfoId){
    return ApiService.fetchData({
        url: `/api/v1/Workbench/WorkBenchSample/associate/Container/${generalInfoId}`,
        method: 'POST',
        data
    })
}

export async function  apiGetSource(url){
    return ApiService.fetchData({
        url: url,
        method: 'GET',
    })
}

//clone workbench

export async function apiPostCloneReceiptWorkbench (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptWorkBench/clone`,
        method: 'post',
        data
    })
}

//notes

export async function apiGetReceiptNotesByReceiptId (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptNote/notes/${data.id}`,
        method: 'GET',
        data
    })
}

export async function apiAddReceiptNotesByReceiptId (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptNote`,
        method: 'POST',
        data
    })
}

export async function apiUpdateReceiptNotesByReceiptId (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptNote`,
        method: 'PUT',
        data
    })
}

export async function apiDeleteReceiptNotesById (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptNote/${data.id}`,
        method: 'DELETE',
        data
    })
}

export async function apiGetUser (data) {
    return ApiService.fetchData({
        url: `/api/v1/access/User`,
        method: 'GET',
        data
    })
}

//assign locations
export async function apiGetContainer (data) {
    return ApiService.fetchData({
        url: `/api/v1/Record/Container`,
        method: 'GET',
        data
    })
}

export async function apiGetContainerById (data) {
    return ApiService.fetchData({
        url: `/api/v1/Record/Container/filter/barcode`,
        method: 'POST',
        data
    })
}

export async function apiAssignLocationToContainer (data,barcode) {
    return ApiService.fetchData({
        url: `api/v1/Workbench/WorkBenchContainer/assign/location/${barcode}`,
        method: 'POST',
        data
    })
}

export async function apiAssignLocationToSample (data,barcode) {
    return ApiService.fetchData({
        url: `api/v1/Workbench/WorkBenchSample/assign/location/${barcode}`,
        method: 'POST',
        data
    })
}

export async function apiAssignProjectToSample (data) {
    return ApiService.fetchData({
        url: `api/v1/Workbench/WorkBenchSample/associate/project`,
        method: 'POST',
        data
    })
}

export async function apiGetProjectIdByProjectNumber (projectNumber) {
    return ApiService.fetchData({
        url: `api/v1/Project/Project/projectId?projectnumber=${projectNumber}`,
        method: 'GET',
    })
}


export async function apiFilterProjectIdByProjectNumber (data) {
    return ApiService.fetchData({
        url: `api/v1/Project/Project/filter/projects`,
        method: 'POST',
        data
    })
}

export async function apiGetExistingRecordAvailable (data) {
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptWorkBench/recordmigration?receiptId=${data.id}`,
        method: 'GET',
    })
}

export async function apiGetProjectsByReceipt(data,receiptId){
    return ApiService.fetchData({
        url: `/api/v1/Receipts/ReceiptProject/page/associated/project?receiptId=${receiptId}`,
        method: 'POST',
        data
    })
}

export async function apiGetReceiptLogs(data) {
    return ApiService.fetchData({
        url: `/api/v1/admin/Logs/receipt?receiptId=${data}`,
        method: 'get',
        data
    })
}

export async function apiGetContainerByIdWithValidation (barcode) {
    return ApiService.fetchData({
        url: `/api/v1/Orders/OrderRecord/return/filter/container?barcode=${barcode}`,
        method: 'GET'
    })
}


export async function apiGetSampleByIdWithValidation (barcode) {
    return ApiService.fetchData({
        url: `/api/v1/Orders/OrderRecord/return/filter/sample?barcode=${barcode}`,
        method: 'GET'
    })
}

export async function apiGetValidateReceiptReturn (data) {
    return ApiService.fetchData({
        url: `/api/v1/Orders/OrderRecord/validate/${data.id}`,
        method: 'get',
        data
    })
}